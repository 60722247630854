import React from "react";
import Header from './Header'

const Layout = props => {
    return (
        <div>
            <Header onExit={props.onExit} />
            <main style={{ marginTop: '70px' }}>
                {props.children}
            </main>
        </div>
    )
}

export default Layout;