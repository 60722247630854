export const REGIONS = {
  EU: "EMEA",
  EA: "EMEA",
  AP: "APJ",
  NA: "AMS",
  WW: "WW"
};
export const MAX_NUM_OF_ATTACHMENTS = 5;
export const MAX_ATTACHMENT_SIZE = 15700000;
export const ISSUE_REASONS = [
  {
    value: "Request Assistance - Configurations",
    label: "Configuration"
  }
];
