import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import "./App.css";
import { logout, renewToken } from "./store/actions";
import ConfirmationDialog from "./components/ConfirmationDialog";
import LandingPage from "./components/LandingPage";
import Layout from "./components/Layout";
import LoginFail from "./components/LoginFail";
import LoginOk from "./components/LoginOk";
import ProductSelectorContainer from "./components/ProductSelection";
import RedirectPFP from "./components/RedirectPFP";
import RSKU from "./components/Rsku";
import WarningPopup from "./components/WarningPopup";
import {
  SESSION_WARNING_TIMEOUT,
  WARNING_PUP_UP_TIME,
  CONFIG_PERSIST_DATA
} from "./components/constants";
import ProtectedRoute from "./hoc/ProtectedRoute";

var interval;

const App = () => {
  const dispatch = useDispatch();
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [isExitDialogLoading, setIsExitDialogLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [t, setT] = useState(false);
  const userSignin = useSelector(state => state.user.userInfo);
  const timer = 1000 * 60 * WARNING_PUP_UP_TIME;
  let timeoutInSeconds = 120;
  let intervalTimer = 1000;
  let expiesAt = Cookies.get("expires") ? Cookies.get("expires") : 0;
  // eslint-disable-next-line no-unused-vars
  const [remainingExpTime, setRemainingExpTime] = useState();
  const [remainingSeconds, setRemainingSeconds] = useState("");
  const userInfo = userSignin ? userSignin : Cookies.getJSON("userInfo");
  const isPartner = userInfo?.userType?.toLowerCase() === "partner";

  useEffect(() => {
    const interval = setInterval(() => {
      appExpiredAt();
      if (remainingExpTime === 2) {
        setOpen(true);
      }
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, remainingExpTime]);

  const appExpiredAt = () => {
    const expTime = expiesAt; // unix timestamp in seconds
    const tm = new Date(expTime * 1000);
    const ct = new Date();
    let diff = (tm.getTime() - ct.getTime()) / 1000;
    diff /= 60;
    diff = Math.abs(Math.round(diff));
    return diff;
  };

  /* Handle Idle Timeout */
  const handleOnIdle = event => {
    if (!t && window.location.pathname.split("/")[1] !== "") {
      intervalTimer = 1000;
      setOpen(true);
      setT(true);

      interval = setInterval(() => {
        timeoutInSeconds = timeoutInSeconds - 1;
        const time = secondsToTime(timeoutInSeconds);
        const displayTime =
          time.m > 0
            ? `${time.m + " Minutes " + time.s + " Seconds"}`
            : `${time.s + " Seconds"}`;
        setRemainingSeconds(displayTime);
        displayTime === "0 Seconds" && logoutOnIdle(true);
      }, intervalTimer);
    }
  };

  function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  const logoutOnIdle = timeO => {
    if (timeO) {
      dispatch(logout());
      handleClose();
    }
  };

  const handleOnActive = event => {};

  const handleOnAction = e => {};

  const { reset } = useIdleTimer({
    timeout: timer,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    stopOnIdle: true,
    debounce: 500
  });

  useEffect(() => {
    let expiryTime = Date.now() + 1000 * 60 * 30;
    const listener = () => {
      const timeNow = Date.now();
      const pastExpiry = timeNow > expiryTime;
      if (document.visibilityState !== "visible") {
        expiryTime = Date.now() + 1000 * 60 * 30;
      }
      if (document.visibilityState === "visible" && pastExpiry) {
        logoutOnIdle(true);
      }
    };
    document.addEventListener("visibilitychange", listener);
    return () => document.removeEventListener("visibilitychange", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetSPCConfigData = () => {
    dispatch({
      type: CONFIG_PERSIST_DATA,
      SPCConfigData: {
        isRegion: {},
        isMarket: {},
        isMarketSelected: false,
        isCountrySelected: false,
        isRegionSelected: true
      }
    });
  };

  const resetSession = () => {
    setT(false);
    setOpen(false);
    clearInterval(interval);
    resetSPCConfigData();
    setRemainingSeconds(SESSION_WARNING_TIMEOUT);
    interval = undefined;
    intervalTimer = 0;
    timeoutInSeconds = SESSION_WARNING_TIMEOUT;
    let bmiData = JSON.parse(localStorage.getItem("bmiData"));
    if (bmiData.isPartnerPortalFlag) {
      const redirectPath = `${process.env.REACT_APP_LOGOUT_PARTNER_URL}`;
      window.location.replace(redirectPath);
    } else {
      window.location.pathname = "/";
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const onExit = useCallback(() => {
    setIsExitDialogOpen(true);
  }, [setIsExitDialogOpen]);

  const closeExitDialog = useCallback(() => {
    setIsExitDialogOpen(false);
    setIsExitDialogLoading(false);
  }, [setIsExitDialogOpen, setIsExitDialogLoading]);

  const handleLogout = useCallback(() => {
    setIsExitDialogLoading(true);
    setTimeout(() => {
      dispatch(logout());
      closeExitDialog();
    }, 100);
  }, [dispatch, setIsExitDialogLoading, closeExitDialog]);

  const handleClose = () => {
    setOpen(false);
    resetSession();
  };

  const handleBtnClick = () => {
    reset();
    intervalTimer = 0;
    // API call for token refresh
    dispatch(renewToken());
    resetSPCConfigData();
    setT(false);
    setOpen(false);
    clearInterval(interval);
  };

  return (
    <>
      <Router>
        <Switch>
          <Layout onExit={onExit}>
            <Route exact={true} path="/" component={LandingPage} />
            <Route exact={true} path="/index.html" component={LoginOk} />
            <Route exact={true} path="/error.html" component={LoginFail} />
            <Route exact={true} path="/pfp/auth" component={RedirectPFP} />
            <ProtectedRoute
              exact={true}
              path="/productselection"
              component={ProductSelectorContainer}
            />
            {!isPartner && (
              <Route>
                <Redirect to="/" />
              </Route>
            )}
          </Layout>
        </Switch>
      </Router>
      <ConfirmationDialog
        open={isExitDialogOpen}
        title="Confirmation"
        content="Do you want to exit?"
        onClose={closeExitDialog}
        loading={isExitDialogLoading}
        onConfirm={handleLogout}
      />
      <WarningPopup
        open={open}
        headerTitle="Warning"
        handleClose={() => handleClose()}
        handleBtnClick={() => handleBtnClick()}
      >
        <p id="transition-modal-description">
          Session will end in{" "}
          <span style={{ color: "red", marginLeft: "4px", marginRight: "4px" }}>
            {remainingSeconds}
          </span>
          . Please click 'Continue' if you want to retain the session.
        </p>
      </WarningPopup>
      <RSKU />
    </>
  );
};

export default App;
