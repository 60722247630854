import jwt_decode from "jwt-decode";
import moment from "moment";

export const getStorageValue = elementId => {
  const element = JSON.parse(localStorage.getItem("bmiData"));
  return element ? element[elementId] : "";
};

export const isTokenExpired = token => {
  if (!token) return true;
  const parsedToken = jwt_decode(token);
  const isExpired = moment.unix(parsedToken.exp).diff(moment(), "seconds") < 1;
  return isExpired;
};
