import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { receiveAccessToken } from "../store/actions";

const LoginOk = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const access_token = Cookies.get("access_token");
  const expires = Cookies.get("expires");

  useEffect(() => {
    if (access_token && expires) {
      // We must resolve it here!
      Promise.resolve(dispatch(receiveAccessToken(access_token, expires))).then(
        res => {
          console.log(res);
          if (res) {
            const userInfo = Cookies.get("userInfo");
            const data = JSON.parse(userInfo);
            const isPartner =
              data.userType && data.userType.toLowerCase() === "partner"
                ? true
                : false;
            if (isPartner) localStorage.setItem("bmiData", userInfo);
            history.push(isPartner ? "/productselection" : "/");
          } else {
            history.push("/error.html");
          }
        }
      );
    } else {
      setTimeout(() => {
        history.push("/");
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3 style={{ color: "green" }}>Login successful.</h3>
      <Link to="/productselection">Please click here continue...</Link>
    </div>
  );
};

export default withRouter(LoginOk);
