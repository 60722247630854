import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rSKUInfo: {}
};

export const rSKUReducer = createSlice({
  name: "rSKU",
  initialState,
  reducers: {
    setRSKUInfo: (state, action) => {
      state.rSKUInfo = action.payload;
    }
  }
});
export const { setRSKUInfo } = rSKUReducer.actions;
export default rSKUReducer.reducer;
