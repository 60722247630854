import { Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import MyAnimation from "./MyAnimation.js";
import RequestAssistanceButton from "./RequestAssistance/RequestAssistanceButton";
import { useDispatch } from "react-redux";
import { setRSKUInfo } from "../reducers/rSKUReducer.js";
const useStyles = makeStyles()(theme => ({
  loaderContainer: {
    position: "fixed",
    zIndex: 100,
    backgroundColor: "#FAFAFA",
    height: "100%",
    width: "100%",
    marginTop: "-60px"
  },
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  pilotContainer: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "60px 0 0 0",
    padding: "0",
    overflowY: "scroll"
  }
}));
export default function ProductSelectorContainer() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isDebug = localStorage.getItem("isDebug") === "true";
  let bmiData = JSON.parse(localStorage.getItem("bmiData"));
  const token = Cookies.get("access_token");
  const userType = bmiData["userType"] || "";
  const dispatch = useDispatch();
  const showSmartSearch = bomData => {
    dispatch(setRSKUInfo(bomData.data));
  };

  const launchSmartSearchOCS = bomData => {
    const { action } = bomData;
    if (Object.values(bomData).length > 0) {
      action === "showSmartSearch" && showSmartSearch(bomData);
      window.parent.postMessage({ rSKUInfo: JSON.stringify(bomData) }, "*");
    }
  };

  useEffect(() => {
    bmiData["routeToMarket"] = bmiData.routeToMarket || "Direct";
    //Setting configJWT as bmiData Object propoerty which will be consumed in one-config-package
    //passing token from the Cookie
    bmiData["configJWT"] = token;
    const productSelector = document.getElementById("product-selector");
    window.onload = () => {
      if (productSelector) {
        loadProductSelector();
      }
    };
    return () => {
      cleanUPSnippet();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanUPSnippet = () => {
    try {
      if (window.OneConfigEmbed?.observer) window.OneConfigEmbed.cleanUp();
      window.embedProductSelector.cleanUp();
    } catch (error) {
      if (isDebug) console.error(error);
    }
  };
  const addSnippetLoader = () => {
    const lottieLoadingFile = `${process.env.REACT_APP_CDN_LOTTIES_URL}product_selector_animation.json`;
    return (
      <div className={classes.loaderContainer}>
        <div className={classes.midPositioner}>
          <Typography variant="h4" align="center">
            {t("common:loader.gifLoaderMessage1")}
          </Typography>
          <Typography align="center">
            {t("common:loader.gifLoaderMessage2")}
          </Typography>
          <MyAnimation
            lottieFile={lottieLoadingFile}
            style={{ height: "300px", width: "400px" }}
          />
        </div>
      </div>
    );
  };
  const openConfigCb = payload => {
    loadOneConfigSnippet(payload);
  };

  const loadOneConfigSnippet = payload => {
    const config = {
      language: "en_US",
      configId: payload.configID || payload.configId,
      region: bmiData.configRegion || "",
      country: bmiData.countryCode || bmiData.country || "",
      contractId: "",
      source: "",
      banded: false,
      productType: payload.productType,
      userEmail: payload.userEmail,
      priceListType: "",
      companyId: bmiData.companyId,
      pricingGeo: bmiData.pricingGeo || "",
      incoTerms: bmiData.incoterms,
      currency: bmiData.currencyCode,
      resellerID: bmiData.resellerID || "",
      routeToMarket: bmiData.routeToMarket,
      originatingAsset: "OCIQ",
      configOrigin: "OCIQ",
      userType: userType,
      token: bmiData.configJWT,
      gateway: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`,
      colourOverrides: {},
      mdcpOrgId: "99",
      spcStartDate: payload?.spcStartDate || "",
      bomUpdateCb: () => {},
      showHeader: false,
      enableExport: true,
      fontSupplied: false,
      isBomCb: true,
      isStandAloneAccess: true,
      showOCFeatures: true,
      showReset: true,
      scrollToChoice: true,
      requestConfigCb,
      storeFront: "IQ",
      platform: "OCIQ",
      buttonTextToCart: "Save Configuration",
      isLiteConfig: true,
      showSearchSKU: payload.showSearchSKU,
      showRequestSKU: payload.showRequestSKU,
      onSearchSKU: launchSmartSearchOCS,
      showLaunchPad: true,
      ...(payload.isUploadFlow && { ...payload })
    };

    const rootElement = document.getElementById("oneConfigEmbed");
    const root = createRoot(rootElement);
    root.render(addSnippetLoader());

    window.OneConfigEmbed.init(config)
      .then(success => {
        root.unmount();
        window.embedProductSelector.cleanUp();
        console.log(success);
      })
      .catch(error => {
        root.unmount();
        console.error(error);
      });
  };
  const requestConfigCb = payload => {
    loadProductSelector(payload);
  };
  const loadProductSelector = payload => {
    let config = {
      gateway: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`,
      token: bmiData.configJWT,
      currencyCode: bmiData.currencyCode || "",
      languageCode: "en_US",
      openConfigCb,
      region: bmiData.configRegion || "",
      country: payload?.country || bmiData.countryCode || bmiData.country || "",
      businessModel: payload?.businessModel || bmiData.routeToMarket || "",
      userEmail: bmiData.userEmail || "",
      userType: userType,
      proCategory: bmiData.productCategory || "compute",
      companyId: bmiData.companyId || "",
      mDCPOrgID: bmiData.mDCPOrgID || "",
      incoTerms: bmiData.incoterms || "",
      resellerID: bmiData.resellerID || "",
      pricingGeo: bmiData.pricingGeo || "",
      purchaseAgreementNumber: "",
      userId: bmiData.userId || "",
      prtnrPortalFlag: true,
      isStandAloneAccess: true,
      isDisplayProdCountry: bmiData.isDisplayProdCountry,
      colourOverrides: {},
      showSearchConfig: true,
      fontSupplied: false,
      showOCFeatures: true,
      showUploadConfig: false
    };
    window.embedProductSelector
      .launch(config)
      .then(success => console.log(success))
      .catch(error => {
        console.error(error);
      });
  };
  return (
    <div className={classes.pilotContainer}>
      <div id="oneConfigEmbed" />
      <div id="embedProductSelector" />
      {bmiData.isPartnerPortalFlag &&
        bmiData.isStandaloneAccess &&
        userType?.toLowerCase() === "partner" && (
          <RequestAssistanceButton data={bmiData} />
        )}
    </div>
  );
}
