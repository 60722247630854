import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userLoginFail } from "../reducers/userReducer";

const LoginFail = () => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.user.error);

  useEffect(() => {
    dispatch(userLoginFail(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3 style={{ color: "red" }}>Login failed.</h3>
      <div>{error}</div>
      <Link to="/">Please re-login.</Link>
    </div>
  );
};

export default LoginFail;
