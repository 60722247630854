import { createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";

const initialState = {
  loading: false,
  userInfo: Cookie.getJSON("userInfo") || null,
  token: Cookie.get("access_token"),
  expiresAt: null,
  error: null
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoginFail: (state, action) => {
      state.loading = false;
      state.token = null;
      state.userInfo = null;
      state.expiresAt = null;
      state.error = action.payload;
    },
    userAccessToken: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload.user;
      state.token = action.payload.token;
      state.expiresAt = action.payload.expiresAt;
      state.error = null;
    },
    clearUserInfo: state => {
      state.loading = false;
      state.userInfo = null;
      state.token = null;
      state.expiresAt = null;
      state.error = null;
    },
    tokenRenewSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    tokenRenewFail: (state, action) => {
      state.loading = false;
      state.userInfo = null;
      state.token = null;
      state.expiresAt = null;
      state.error = action.payload;
    },
    tokenRenewRequest: state => {
      state.loading = true;
      state.token = null;
      state.expiresAt = null;
    }
  }
});
export const {
  userLoginFail,
  userAccessToken,
  clearUserInfo,
  tokenRenewSuccess,
  tokenRenewFail,
  tokenRenewRequest
} = userReducer.actions;
export default userReducer.reducer;
