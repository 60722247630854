import { useMutation } from "@apollo/client";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import AddNotesAttachmentsList from "./AddNotesAttachmentsList";
import { CREATE_CASE_REQUEST_ASSISTANCE } from "../../GraphQL";
import displayNotistack from "../../helpers/SnackBarUtils";
import {
  MAX_ATTACHMENT_SIZE,
  MAX_NUM_OF_ATTACHMENTS,
  REGIONS,
  ISSUE_REASONS
} from "./constants";

const useStyles = makeStyles()(theme => ({
  rootIcon: {
    height: 150,
    width: 150
  },
  dragNdropStyle: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 10,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: theme.palette.grey[400]
  },
  textColor: {
    color: "#0096d6"
  },
  asterisk: {
    color: "#FF7272"
  },
  disableText: {
    color: theme.palette.text.disabled
  },
  checkCircleOutlineRoundedIcon: {
    width: "auto",
    height: "150px",
    color: "rgb(0, 150, 214)"
  },
  dialogContnet: {
    textAlign: "center"
  },
  typography: {
    fontWeight: "700"
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    display: "flex",
    alignItems: "center"
  }
}));

export default function RequestAssistanceDialog({ setOpen, data }) {
  const { classes } = useStyles();
  const [description, setDescription] = useState("");
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState("Request Assistance - Configurations");
  const [caseNumber, setCaseNumber] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  const showWarning = () => {
    displayNotistack.warning("You have hit the max attached limit​.", {
      vertical: "bottom"
    });
  };

  const [RequestAssistance] = useMutation(CREATE_CASE_REQUEST_ASSISTANCE, {
    onCompleted(data) {
      if (data.RequestAssistance) {
        setCaseNumber(data.RequestAssistance);
        setLoading(false);
        setToggle(true);
      } else {
        displayNotistack.error(t("errorMessage.graphQLError"), "error", 6000);
        setLoading(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      if (true) {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
    }
  });
  const callRequestAssistance = createAttaches => {
    RequestAssistance({
      variables: {
        input: {
          caseType: "CPQ Channel",
          caseReason: value,
          caseScenario: value,
          accountName: "HPI",
          title: value,
          description: description,
          priorityCode: "Medium",
          originatingSystem: "OneConfig",
          requestorRole: "Partner",
          requestorLanguage: "EN",
          caseRequestorEmail: data.userEmail,
          countryOfSubmitter: data.country || data.countryCode || "",
          region: REGIONS[data.configRegion] || "WW",
          customerSegment: "",
          updateReceived: "",
          pbmhpSalesRepEmail: "",
          relatedDocuments: "",
          routeToMarket: "",
          caseRequestor: "",
          subRegion: "",
          createAttaches: createAttaches
        }
      }
    });
  };

  const handleSave = () => {
    setLoading(true);
    if (attachments.length) {
      const createAttaches = attachments.map(attachment => ({
        file: attachment.documentBody,
        fileName: attachment.fileName
      }));
      callRequestAssistance(createAttaches);
    } else {
      callRequestAssistance();
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      let existingAttachments = attachments;
      let fileExceedsSize = [];
      let invalidFiles = [];
      let isLimitExceeds = false;
      if (rejectedFiles) {
        console.log(rejectedFiles, "rejectedFiles");
        rejectedFiles.forEach(each => {
          if (each?.size > MAX_ATTACHMENT_SIZE) {
            fileExceedsSize.push(each?.name);
          } else {
            invalidFiles.push(each?.name);
          }
        });
      }
      if (fileExceedsSize.length > 0) {
        enqueueSnackbar(
          fileExceedsSize.join(", ") +
            " " +
            t("snackbar.reUploadFileLowerThan15MB"),
          {
            variant: "error",
            persist: false
          }
        );
      }
      if (invalidFiles.length > 0) {
        displayNotistack.error(t("snackbar.fileFormat"));
      }
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          if (!isLimitExceeds) {
            let encoded = reader?.result
              ?.toString()
              .replace(/^data:(.*,)?/, "");
            if (encoded?.length % 4 > 0) {
              encoded += "=".repeat(4 - (encoded?.length % 4));
            }
            const newAttachment = {
              fileName: file.name,
              createdAt: moment(Date.now()).format("DD/MM/YYYY[ ]HH:mm:ss"),
              userName: data.userEmail,
              newRecord: true,
              uploadedTime: new Date(),
              mimeType: file.name?.substr(file.name.lastIndexOf(".") + 1) || "",
              documentBody: encoded || "",
              file: reader?.result
            };

            existingAttachments = [...existingAttachments, newAttachment];
            let newlength = existingAttachments.filter(
              each => each.newRecord
            ).length;
            if (newlength === MAX_NUM_OF_ATTACHMENTS) {
              setAttachments(existingAttachments);
              showWarning();
            } else if (newlength > MAX_NUM_OF_ATTACHMENTS) {
              displayNotistack.info(t("snackbar.fileLengthLimit"), {
                vertical: "bottom"
              });
              isLimitExceeds = true;
            } else {
              setAttachments(existingAttachments);
            }
          }
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachments]
  );

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        open={!toggle}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <Typography variant="h6">
            <b>{t("requestAssistance.requestAssist")}</b>
          </Typography>
          <Grid className={classes.closeButton}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                setOpen(false);
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                <b>{t("requestAssistance.issueTitle")}</b>
                <span className={classes.asterisk}>*</span>
              </Typography>

              <Paper>
                <Select
                  margin="dense"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={handleChange}
                  fullWidth
                >
                  {ISSUE_REASONS.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>{t("requestAssistance.description")}</b>
                <span className={classes.asterisk}>* </span>
              </Typography>
              <Paper>
                <TextField
                  placeholder={t("requestAssistance.placeholder")}
                  id="outlined-multiline-static"
                  multiline
                  variant="outlined"
                  fullWidth={true}
                  minRows={5}
                  onChange={e => setDescription(e.target.value)}
                  InputProps={{
                    inputProps: {
                      maxLength: 500
                    }
                  }}
                  required
                />
              </Paper>
            </Grid>
            {attachments?.filter(each => each.newRecord).length <
              MAX_NUM_OF_ATTACHMENTS && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <b>{t("requestAssistance.screenshotOrAttachmentsTitle")}</b>
                </Typography>
                <Typography className={classes.disableText}>
                  {t("requestAssistance.inputScreenshot")}
                </Typography>

                <Grid item>
                  <Box
                    p={1}
                    textAlign="center"
                    className={classes.dragNdropStyle}
                  >
                    <Dropzone
                      onDrop={handleDrop}
                      multiple
                      accept={{
                        "image/png": [".png"],
                        "image/jpg": [".jpg", ".jpeg"],
                        "text/csv": [".csv"],
                        "application/msword": [".doc"],
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                          [".docx"],
                        "application/vnd.ms-excel": [".xls"],
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                          [".xlsx"]
                      }}
                      maxSize={MAX_ATTACHMENT_SIZE}
                      disabled={loading}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Grid container direction="column" {...getRootProps()}>
                          <Typography
                            variant="body1"
                            style={{ marginTop: "20px" }}
                          >
                            {t("requestAssistance.dropFiles")}
                          </Typography>
                          <Typography variant="body1">
                            {t("requestAssistance.or")}
                            <Typography className={classes.textColor}>
                              {t("requestAssistance.browseFolder")}
                            </Typography>
                            <Typography>
                              {t("requestAssistance.fileLimit")}
                              <br />
                              {t("requestAssistance.supportedFile")}
                              {t("requestAssistance.supportedFiles")}
                            </Typography>
                          </Typography>
                          <input
                            {...getInputProps()}
                            style={{ visibility: "hidden", width: 0 }}
                          />
                        </Grid>
                      )}
                    </Dropzone>
                  </Box>
                </Grid>
              </Grid>
            )}
            {attachments.length > 0 && (
              <Grid item xs={12}>
                <AddNotesAttachmentsList
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </Grid>
            )}
            <Grid item container className={classes.buttonContainer}>
              <Button
                id="createCase"
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!description?.length || loading}
              >
                {t("requestAssistance.save")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {toggle && (
        <Dialog open={toggle}>
          <DialogTitle>
            <Typography variant="h6">
              {t("requestAssistance.requestAssist")}
            </Typography>
          </DialogTitle>
          <CheckCircleOutlineRoundedIcon
            color="primary"
            className={classes.checkCircleOutlineRoundedIcon}
          />
          <DialogContent className={classes.dialogContnet}>
            <Typography className={classes.typography}>
              {t("requestAssistance.requestAssistanceTicket")} &nbsp;
              {caseNumber}&nbsp; {t("requestAssistance.alreadyCreated")}
            </Typography>
            <Typography>{t("requestAssistance.notification")}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setToggle(false);
                setOpen(false);
              }}
              color="primary"
            >
              {t("requestAssistance.close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
