import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
  const token = Cookie.get("access_token");
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/index.html") return;
    if (token) {
      const userInfo = jwt_decode(token);
      const isPartner = userInfo.userType?.toLowerCase() === "partner";
      const notExpired = userInfo.exp > new Date().getTime() / 1000;
      if (isPartner) {
        if (location.pathname !== "/productselection")
          history.replace("/productselection");
        if (notExpired) setIsAuth(true);
      } else {
        setIsAuth(notExpired);
      }
    } else {
      setIsAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location]);

  if (isAuth === null) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default ProtectedRoute;
